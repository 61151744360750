/** @jsx jsx */
import { jsx, Heading } from "theme-ui";
import Layout from "../components/layout-blog";
import Link from "../components/link";

const PostLink = ({ post }) => (
  <article itemScope itemType="http://schema.org/Article" sx={{ mb: 4 }}>
    <header>
      <Heading mb={0}>
        <Link to={post.slug} itemProp="url">
          <span itemProp="headline">{post.frontmatter.title}</span>
        </Link>
      </Heading>
      <small>{post.frontmatter.date}</small>
    </header>
    <section>
      <p itemProp="description" sx={{ m: 1 }} />
      {post.excerpt}
    </section>
  </article>
);

const IndexPage = ({
  data: {
    allMdx: { edges },
  },
}) => {
  const Posts = edges
    // .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map((edge) => <PostLink key={edge.node.id} post={edge.node} />);
  return <Layout>{Posts}</Layout>;
};
export default IndexPage;

export const pageQuery = graphql`
  query {
    allMdx(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          slug
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`;
